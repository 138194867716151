.sidemenu .name {
    margin: 0 0 10px;
    line-height: normal;
    padding: 10px 20px;
    font-weight: 500 !important;
}
.sidemenu input.ant-input.ant-input-outlined {
    border-color: #3b8eb9;
    padding: 0 5px;
    border-radius: 3px 0 0 3px;
    height: 28px;
}
.sidemenu .ant-input-search-button {
    border-color: #3b8eb9;
    color: #3b8eb9;
    border-radius: 0 3px 3px 0;
    height: 28px;
}
.sidebar-menus.ant-menu li {
    margin: 0;
    width: 100%;
    line-height: normal;
    height: auto;
}
.sidebar-menus.ant-menu li:first-child {
    border-top: 0;
}
.sidebar-menus.ant-menu {
    padding: 4px 6px;
}
.sidebar-menus.ant-menu > li.ant-menu-item {
    padding: 10px 14px !important;
    background: transparent;
    margin: 1px 0px;
    border-bottom: 1px solid #f0f0f0;
    line-height: normal;
}
.sidebar-menus.ant-menu li.ant-menu-item:hover,
.sidebar-menus.ant-menu li.ant-menu-item.ant-menu-item-selected {
    background: #3b8eb9 !important;
    color: #ffffff !important;
}
.sidebar-menus.ant-menu li.ant-menu-submenu .ant-menu-submenu-title:hover,
.sidebar-menus.ant-menu li.ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu-title {
    background: #3b8eb9 !important;
    color: #ffffff !important;
}

.sidebar-menus.ant-menu li.ant-menu-submenu .ant-menu-submenu-title {
    width: 100%;
    height: auto;
    color: rgba(0, 0, 0, 0.88);
    padding: 10px 14px !important;
    background: transparent;
    margin: 1px 0px;
    border-bottom: 1px solid #f0f0f0;
    line-height: normal;
}

.sidebar-menus .ant-menu-sub {
    background: transparent !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 6px !important;
    padding-top: 0px !important;
}

.sidebar-menus .ant-menu-sub li.ant-menu-item {
    border: none;
    padding: 6px 12px 6px 12px !important;
    line-height: normal;
    color: #777777;
    background: transparent;
    margin: 3px 0;
}

.sidebar-menus .ant-menu-sub li.ant-menu-item:hover {
    border: none;
    color: #3b8eb9 !important;
    background: transparent !important;
}

.sidebar-menus .ant-menu-sub li.ant-menu-item.ant-menu-item-selected{
    color: #3b8eb9 !important;
    background: transparent !important;
}

.sidebar-menus.ant-menu.ant-menu-inline-collapsed li.ant-menu-item span.ant-menu-title-content, .sidebar-menus.ant-menu.ant-menu-inline-collapsed li.ant-menu-submenu span.ant-menu-title-content {
    display: none;
}
.sidebar-menus.ant-menu.ant-menu-inline-collapsed li.ant-menu-item {text-align: center;}

.sidebar-menus.ant-menu.ant-menu-inline-collapsed  .ant-menu-submenu-title {
    text-align: center;
}
.sidebar-menus.ant-menu li span.anticon {
    font-size: 16px;
}

/* Mobile Drawer Style */

.mobile-drawer-sidebar .ant-drawer-body {
    padding: 0;
    background-color: #f8fafb;
}
.mobile-drawer-sidebar .ant-drawer-header {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 0;
}

.mobile-drawer-sidebar .ant-drawer-header button.ant-drawer-close {
    margin: 0;
    color: #fff;
}