@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
html body {
  font-family: 'Roboto', sans-serif !important;
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
}
div, td, th, h1, h2, h3, h4, h5, h6, li, p, button, input, select, textarea {
  font-family: 'Roboto', sans-serif !important;
  line-height: normal;
}
* :focus,
* :focus-within {
  outline: none !important;
  box-shadow: none !important;
}
.App {
  text-align: center;
}
h1 {
  font-size: 20px !important;
  font-weight: bold !important;
  line-height: normal;
}
h2 {
  font-size: 16px !important;
  font-weight: bold !important;
  line-height: normal;
}
h3 {
  font-size: 14px !important;
  font-weight: normal !important;
}
p, li, td, th, button, .ant-btn, input, select, textarea {
  font-size: 12px !important;
  line-height: normal;
}
:where(.css-dev-only-do-not-override-1vsv1m8).ant-btn, :where(.css-1vsv1m8).ant-btn {
  padding: 8px 20px;
  height: auto;
  font-weight: bold;
}
.ant-btn-primary.ant-btn-color-primary {
  background: #2373a5;
}
.ant-menu li {
  font-size: 14px !important;
  line-height: normal;
}
.ant-menu li li {
  font-size: 13px !important;
  line-height: normal;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.humberge-btn {
  width: auto !important;
}

.humberge-btn .anticon svg {
  font-size: 20px;
}
.sidemenu input.ant-input {
  height: 32px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.notification-dropdown sup.ant-badge-count {
    top: -5px;
    height: 15px;
    min-width: 15px;
    line-height: 15px;
    font-size: 10px;
}
/* .records-table.ant-table-wrapper tr:nth-child(odd) td.ant-table-cell-fix-left {
    background: rgb(245, 245, 245);
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.mainlayout {
  padding: 24px;
  .ant-input-affix-wrapper {
    height: 26px !important;
    border-radius: 3px 0px 0px 3px !important;
    padding: 0 4px !important;
    border-color: #d9d9d9;
    border-right: none;
  }
  
  .ant-input-group-wrapper button {
    border-left: none !important;
    border-radius: 0px 3px 3px 0px !important;
    height: 26px !important;
    border-color: #d9d9d9 !important;
  }
}

.anticon svg {
  font-size: 14px;
}
tbody.ant-table-tbody tr:nth-child(2n+2) {
  background: #F2F2F2 !important;
}
.ant-table-container {
  border-color: #D7D7D7 !important;
}
th.ant-table-cell,
td.ant-table-cell {
  border-radius: 0 !important;
  border-color: #D7D7D7 !important;
}
th.ant-table-cell {
  padding: 5px 5px !important;
  text-transform: capitalize;
  &.drag-th {
    padding: 0 5px !important;
    > div {
      padding: 8px 0px;
    }
  }
}
td.ant-table-cell {
  padding: 3px 5px !important;
  color: #000;
}

td.action .ant-btn-icon-only {
  padding: 0 !important;
}
th.action, td.action {
  width: 60px;
}
td.action button {
  width: auto !important;
  min-width: 20px;
  height: auto;
}

th.ant-table-cell {
  background-color: rgb(59, 142, 185) !important;
  color: white !important;
  border-radius: 0px;
  .filter {
    position: absolute;
    right: 0;
    color: #fff !important;
    opacity: 1;
    z-index: 1;
    top: 50%;
    transform: translate(0px, -50%);
  }
  span.ant-table-column-sorter {
    opacity: 0;
    color: #fff !important;
  }
  
  &[aria-sort] span.ant-table-column-sorter {
    opacity: 1;
  }
}
.NoOfVehicles {
  text-align: right;
  > div {
    margin-left: auto;
    justify-content: flex-end;  
    text-align: right;
  }
  .react-resizable {
    justify-content: flex-end;
    padding-right: 20px;
    margin-left: auto;
  }
}
.ant-table-cell {
  .react-resizable {
    position: static;
    display: flex;
  }
  .react-resizable-handle {
    position: absolute;
    right: -5px;
    bottom: 0px;
    z-index: 1;
    width: 10px;
    height: 100%;
    cursor: col-resize;
    padding: 0;
    background: none;
  }
}

.vehicle-list {
  width: 100%;
  max-width: 1000px;
}
.tableContainer {
  width: 100%;
  scroll-behavior: smooth;
  overflow: auto;
  .pagination {
    background: #F2F2F2 !important;
    padding: 3px 5px;
    border: 1px solid #D7D7D7;
    border-top: none;  
    .ant-flex {
      border: 1px solid #D7D7D7;
      border-right: none;
      border-radius: 4px;
      overflow: hidden;
    }
    button {
      height: auto;
      width: 25px !important;
      padding: 3px;
      background: #fff !important;
      border-color: #D7D7D7 !important;
      border-radius: 0;
      border-left: none;
      border-top: none;
      text-align: center;
      border-bottom: none;
      &.ant-btn-primary {
        background: #3b8eb9 !important;
      }
      svg {
        width: 12px;
      }
    }
  }

}
button {
  .icon {
    width: 20px;
    height: auto;
  }
}
.vehicle-list .actions {
  width: 30px;
  text-align: center;
  &.drag {
    width: 30px !important;
    svg {
      font-size: 20px;
    }
  }
  button {
    padding: 0 !important;
    height: auto;
    background: transparent !important;
    width: auto !important;
    .icon {
      width: 20px;
      height: auto;
    }
  }
}
.setting-modal {
    max-width: 360px;
}
.table-setting {
  width: 100%;
  border-bottom: none;
  th, td {
    border-right: 1px solid #D7D7D7;
    border-bottom: 1px solid #D7D7D7;
    text-align: left;
    &:last-child {
      border-right: none;
    }
  }
  th {
    background: #3b8eb9;
    color: #fff;
    padding: 4px 5px;
  }
  tbody tr {
    background-color: #d8eaf6;
  }
  td {
    padding: 1px 5px;
    border-bottom-color: #a5c9e9;
    &.action {
      width: 20px;
      svg {
        background: #3b8eb9;
        color: #fff;
        border-radius: 20px;
        font-size: 20px;
        padding: 4px;
        font-weight: bold;
      }
    }
    
    &:first-child {
      background-color: #fff;
      border-bottom-color: #D7D7D7;
    }
    
  }
}

h1 span, h2 span {
  color: #3b8eb9;
}
.vehicle-form {
  border-top: 8px solid #3b8eb9;
  border-left: 1px solid #D7D7D7;
  border-right: 1px solid #D7D7D7;
  border-bottom: 1px solid #D7D7D7;
  padding: 15px 20px;
  width: 100%;
  .ant-form-item {
    margin: 0 0 15px 0;
  }
  .ant-form-item-control-input {
    min-height: 10px;
  }
  label {
    min-width: 100px;
    height: 30px !important;
    text-align: left;
    &:after {
      display: none;
    }
  }
  input.ant-input {
    border: 1px solid #999999;
    border-radius: 3px;
    width: 100%;
    padding: 3px;
    height: 30px;
  }
  
  input[type="number"] {
    width: 50px;
  }
  select {
    border: 1px solid #999999;
    border-radius: 3px;
    width: 100%;
    padding: 3px 1px;
    max-width: 280px;
    height: 30px;
  }
  select[multiple] {
    height: 130px;
  }
}

.ant-col.ant-form-item-label {
  white-space: normal;
}
.vehicle-form .NoVehicles .ant-row {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.vehicle-form .NoVehicles .ant-form-item-control  {
  min-width: 50px;
}
.vehicle-form .NoVehicles button {
  width: 100%;
  margin: 15px 0 0 0;
  padding: 8px;
  height: auto;
  background: #559bcd;
}

.vehicle-form .NoVehicles button:hover {
  background: #3b8eb9 !important;
}
button.cancel {
  background: #7d7d7d;
  color: #fff;
  border-color: #7d7d7d;
}
button.cancel:hover {
  background: #000 !important;
  color: #fff !important;
  border-color: #000 !important;
}
@media screen and (max-width: 580px) {
  .sm-hide, .Model, .NoOfVehicles {
    display: none !important;
  }
  .mainlayout {
    padding: 16px;
  }
}