.vehicleModal {
    width: 100% !important;
    max-width: 980px;
    .ant-modal-content {
        border-radius: 0 !important;
        box-shadow: none !important;
        border: 1px solid #d7d7d7 !important;
        border-top: 8px solid #3b8eb9 !important;
        padding: 15px 20px;
        width: 100%;
    }
}

.vehicleModal {
    width: 100% !important;
    max-width: 980px;
    .ant-modal-close {
        display: none;
    }
    .ant-btn.button {
        min-width: 100px;
    }
    
}
.search_form {
    .ant-input-affix-wrapper {
      height: 26px !important;
      border-radius: 3px 0px 0px 3px !important;
      padding: 0 4px !important;
      border-color: #d9d9d9;
      border-right: none;
    }
    
    .ant-input-group-wrapper button {
      border-left: none !important;
      border-radius: 0px 3px 3px 0px !important;
      height: 26px !important;
      border-color: #d9d9d9 !important;
    }
  }
.vehicleModal .ant-modal-content {
    border-radius: 0 !important;
    box-shadow: none !important;
    border: 1px solid #d7d7d7 !important;
    border-top: 8px solid #3b8eb9 !important;
    padding: 15px 20px;
    width: 100%;
}
.table-setting {
    tbody.ant-table-tbody tr.ant-table-row{
        background: #EEEEEE !important;
        td {
            background: #EEEEEE !important;
        }
        &.active {
            background: #c4ddeb !important;
            td {
                background: #c4ddeb !important;
            }
        }
    }
}
.check-col {
    width: 30px;
    text-align: center;
}

.check-arrow {
    position: relative;
    z-index: 0;
}

.check-arrow label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
}

.check-arrow .anticon-right-circle {
    color: #ccc;
}

.active .check-arrow .anticon-right-circle {
    color: #3b8eb9;
}