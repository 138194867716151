.vehicle-list.task-location {
    max-width: 510px;
}
[draggable="true"] td.ant-table-cell-row-hover:not(.actions) {
    cursor: grab;
}
td.ant-table-cell.actions.drag {
    width: 30px;
    text-align: center;
    padding: 0;
    cursor: grab;
    svg {font-size: 16px;}
}
.task-location {
    th.ant-table-cell.Order {
        width: 30px;
    }

    th.ant-table-cell.Order div {
        padding: 0;
        text-align: right;
    }

    .Order {
        text-align: right;
    }
}